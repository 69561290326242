<template>
  <div :class="alertMod" v-if="alertModel">
    <button class="closebtn" @click="closeAlert()">&times;</button>
    <strong>{{ messageWaring }}!</strong> {{ alertContent }} .
    <p>{{ auctDetails }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alertModel: false,
    //  negStatus: false,
      alertMod: "alert",
      messageWaring: "Warning",
      // alertContent: "New negotiation was initated by customer",
    };
  },
  props: {
    detailsList: Array,
    alertContent: String
  },
  mounted() {
    if (this.detailsList) {
      this.alertModel = this.detailsList.negotiationStatus;
      console.log(this.detailsList);
    }
  },
  methods: {
    closeAlert() {
      this.alertModel = false;
    },
  },
};
</script>

<style>
.alert {
  padding: 20px;
  background-color: #b9dbe4;
  color: rgb(19, 6, 6);
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
</style>
