<template>
  <div>
    <div>
      <div class="wel-user">
        <img
          src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
        />
        <div>
          <a> Welcome</a>
          <span>{{ dasBoardData.custName }}</span>
        </div>
        <!-- <a>
          <i class="fad fa-edit"></i>
        </a> -->
      </div>
      <hr />

      <a class="toggle-i-p-m" @click="toggleList()">Menu</a>


      <ul  v-if="showList"
        class="nav myaccount-tab-trigger"
        id="account-page-tab"
        role="tablist"
      >
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Bids"
            ><i class="fa fa-car"> </i>Active Auctions</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Research"
            ><i class="fa fa-search"></i> Research
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Wishlist"
            ><i class="fas fa-heart"></i> My Wishlist
            <!-- <span class="not-count">{{
              dasBoardData.wishlist
            }}</span> -->
            
            </router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/PendingCustomer"
            ><i class="fa fa-clock" aria-hidden="true"></i> Pending Auction
            <!-- <span class="not-count">{{
              dasBoardData.pending
            }}</span> -->
            
            </router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/ClosingCustomer"
            ><i class="fa fa-window-close" aria-hidden="true"></i> Closing
            Auction
            <span class="not-count">{{
              dasBoardData.closing
            }}</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/WonCustomer"
            ><i class="fa fa-trophy" aria-hidden="true"></i> Won Auction
            <span class="not-count">{{ dasBoardData.won }}</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/ArrivedCustomer"
            ><i class="fa fa-location-arrow" aria-hidden="true"></i> Arrived
            Auction
            <span class="not-count">{{
              dasBoardData.arrived
            }}</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/DeliveredCustomer"
            ><i class="fa fa-truck" aria-hidden="true"></i> Delivered Auction
            <span class="not-count">{{
              dasBoardData.delivered
            }}</span></router-link
          >
        </li>
        <!-- <hr /> -->
      </ul>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
export default {
  data() {
    return {
      dasBoardData: [],
      userinfo: "",
      showList:true,
    }
  },
  name: "SideMenu",
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    var className = document.getElementsByClassName("nav-link");
    for (var i = 0; i < className.length; i++) {
      if (className[i].className.includes("router-link-active")) {
        className[i].classList.add("active");
      }
    }

    if (this.loggedIn) {
      this.getDashboardProfileFe(this.currentUser.customerId, "customer");
    }
  },
  methods: {
    getDashboardProfileFe(custId, type) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getDashboardProfileFe(custId, type)
          .then((response) => {
            this.dasBoardData = response.data.Data[0];
            // console.log(this.dasBoardData);
            // window.scrollTo(0, 0);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    toggleList(){
      this.showList = !this.showList;
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/").catch(() => {
        "sidemenu";
      });
    },
  },
};
</script>




<style scoped>
.nav-item a:hover {
  background-color: #002868 !important;
  color: white !important;
}
</style>
