<template>
  <navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenu></sideMenu>
              </div>
              <div class="col-lg-9">
                <div>
                  <h4 class="comm-title">Pending Auctions</h4>
                </div>
                <div v-if="!items.length">
                  <NotFound></NotFound>
                </div>
                <div v-else>
                  <!-- TODO negotiation cusomer alert -->
                  <div
                    v-if="
                      negotiationDetails.NegBysellerId > 0 &&
                      negotiationDetails.isnegpossiable == 135222
                    "
                    class="pending-alert"
                  >
                    <Alert
                      :alertContent="'Negotiation was initated by seller'"
                      :detailsList="negotiationDetails"
                    ></Alert>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-4"
                      v-for="VehicleDetails in items"
                      :key="VehicleDetails.vehId"
                      style="margin-bottom: 15px; padding: 0px"
                    >
                      <div>
                        <div class="">
                          <!--new-main-tile-->
                          <section>
                            <div class="" style="margin: 0px 6px">
                              <div class="">
                                <div class="carTileWrap shine-overlay">
                                  <div class="shine"></div>
                                  <div
                                    class="cwrapImage"
                                    style="position: relative"
                                  >
                                    <router-link
                                      :to="{
                                        name: 'AuctionDetails',
                                        params: {
                                          url_key: VehicleDetails.aucName,
                                        },
                                      }"
                                    >
                                      <img
                                        :src="path + VehicleDetails.vehImage1"
                                        class="img-fluid img-responsive border5px"
                                      />
                                    </router-link>

                                    <div class="topleft">
                                     
                                    </div>
                                    <div class="topright">
                                      <div style="display: flex"></div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="vehicleName">
                                      <router-link
                                        :to="{
                                          name: 'AuctionDetails',
                                          params: {
                                            url_key: VehicleDetails.aucName,
                                          },
                                        }"
                                      >
                                        <h5>
                                          {{ VehicleDetails.brandName }}
                                          {{ VehicleDetails.modelName }}
                                          {{ VehicleDetails.manYear }}
                                        </h5>
                                      </router-link>
                                    </div>
                                    <div>
                                      <hr style="margin: 0px" />
                                      <div
                                        class="row"
                                        v-if="
                                          VehicleDetails.negotiationStatus !=
                                            true &&
                                          VehicleDetails.CustomerRank == 1 && 
                                          VehicleDetails.isRejected == null &&
                                          (VehicleDetails.custNegAmount == null 
                                          || VehicleDetails.sellerNegAmount == null)
                                        "
                                      >
                                        <div class="col-12">
                                          <div class="text-center">
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="reAuction(VehicleDetails)"
                                            >
                                              <span>Negotiate</span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="row"
                                        v-else-if="
                                          VehicleDetails.negotiationStatus ==
                                            true &&
                                          VehicleDetails.approveStatus != 1 &&
                                          VehicleDetails.CustomerRank == 1 
                                          &&
                                          VehicleDetails.isRejected == null &&
                                          (VehicleDetails.custNegAmount != null 
                                          || VehicleDetails.sellerNegAmount != null)
                                        "
                                      >
                                        <div class="col-12">
                                          <div class="text-center">
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="reAuction(VehicleDetails)"
                                            >
                                              <span
                                                ><i
                                                  class="fad fa-handshake"
                                                ></i>
                                                Negotiating</span
                                              >
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- second neg -->
                                      <div
                                        class="row"
                                        v-else-if="
                                          VehicleDetails.negotiationStatus ==
                                            true &&
                                          VehicleDetails.approveStatus != 1 &&
                                          VehicleDetails.CustomerRank == 2 
                                          &&
                                          VehicleDetails.isRejected == 1 &&
                                          (VehicleDetails.custNegAmount != null 
                                          || VehicleDetails.sellerNegAmount != null)
                                        "
                                      >
                                        <div class="col-12">
                                          <div class="text-center">
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="reAuction(VehicleDetails)"
                                            >
                                              <span
                                                ><i
                                                  class="fad fa-handshake"
                                                ></i>
                                                Negotiating</span
                                              >
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    
                                      <div
                                        class="row"
                                        v-else-if="
                                          VehicleDetails.negotiationStatus ==
                                            true &&
                                          VehicleDetails.CustomerRank == 2 && 
                                          VehicleDetails.isRejected == 1  &&

                                          (VehicleDetails.custNegAmount == null 
                                          || VehicleDetails.sellerNegAmount == null)
                                         
                                        "
                                      >
                                        <div class="col-12">
                                          <div class="text-center">
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="reAuction(VehicleDetails)"
                                            >
                                              <span>Negotiate</span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      
                                      

                                      <div
                                        class="row"
                                        v-else-if="
                                          VehicleDetails.negotiationStatus ==
                                            1 &&
                                          VehicleDetails.approveStatus == 1
                                        "
                                      >
                                        <div class="col-12">
                                          <button
                                            class="tile-bid-now"
                                            tabindex="-1"
                                          >
                                            <span
                                              style="color: unset; cursor: auto"
                                              >Waiting For Seller Approval</span
                                            >
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn pending-cust-pop"
                  v-if="openModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close"
                    @click="(openModel = false), (message = '')"
                  >
                    x
                  </button>

                  <div class="row">
                    <div class="col-md-6">
                      <table class="negTable">
                        <tr>
                          <th><h4>Negotiation Details</h4></th>
                          <th></th>
                        </tr>
                        <tr>
                          <td>Brand</td>
                          <td style="text-align: right">
                            <span
                              >{{ auctionEdit.brandName }}
                              {{ auctionEdit.modelName }}
                              {{ auctionEdit.manYear }}</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>VIN</td>
                          <td style="text-align: right">
                            <span>#{{ auctionEdit.vin }}</span>
                          </td>
                        </tr>
                        <tr
                          v-if="
                            auctionEdit.custNegAmount &&
                            auctionEdit.sellerNegAmount == true
                          "
                        >
                          <td style="cursor: auto !important">
                            <strong
                              ><span style="cursor: auto !important"
                                >Waiting for seller approval</span
                              ></strong
                            >
                          </td>
                        </tr>

                        <tr v-if="auctionEdit.approveStatus != true">
                          <td>Bid amount</td>
                          <td style="text-align: right">
                            <span>
                              {{
                                $n(
                                  auctionEdit.LatestBidAmount,
                                  "currency",
                                  language
                                )
                              }}</span
                            >
                          </td>
                        </tr>
                        <tr
                          v-if="
                            auctionEdit.sellerNegAmount &&
                            auctionEdit.approveStatus != true
                          "
                        >
                          <td>From Seller</td>
                          <td style="text-align: right">
                            <span>{{
                              $n(
                                auctionEdit.sellerNegAmount,
                                "currency",
                                language
                              )
                            }}</span>
                          </td>
                        </tr>
                        <tr
                          v-if="
                            auctionEdit.custNegAmount &&
                            auctionEdit.approveStatus != true
                          "
                        >
                          <td>Previous offer</td>
                          <td style="text-align: right">
                            <span>{{
                              $n(
                                auctionEdit.custNegAmount,
                                "currency",
                                language
                              )
                            }}</span>
                          </td>
                        </tr>

                        <tr v-if="auctionEdit.approveStatus != true">
                          <td>New offer</td>
                          <td>
                            <input
                              class="form-control neg-a"
                              type="number"
                              v-model="negotiationAmount"
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-6">
                      <div class="scroll-custome">
                        <div class="rightbox">
                          <h6 class="text-center">Negotiation History</h6>
                          <div
                            class="rb-container"
                            v-if="negotiationTimeLine.length !== 0"
                          >
                            <ul class="rb">
                              <!-- loop -->
                              <li
                                v-for="negotiationTime in negotiationTimeLine"
                                :key="negotiationTime.negotiateId"
                                class="rb-item"
                                ng-repeat="itembx"
                              >
                                <div class="timestamp">
                                  {{
                                    formatDateTime(
                                      negotiationTime.negotiationDate
                                    )
                                  }}
                                  <!-- 3rd May 2020<br />
                                  7:00 PM -->
                                </div>
                                <span class="p-c-v-name">{{
                                  negotiationTime.userName
                                }}</span>
                                <span class="p-amount">{{
                                  $n(
                                    negotiationTime.negotiationAmount,
                                    "currency",
                                    language
                                  )
                                }}</span>
                              </li>
                            </ul>
                          </div>
                          <div v-else>
                            <img
                              src="/assets/images/no-records.png"
                              class="not-found-imgg"
                              style="width: 40%"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <div class="row">
                    <div class="col-4">
                      <div>
                        <button
                          v-if="
                            auctionEdit.approveStatus != true &&
                            auctionEdit.sellerNegAmount != null
                          "
                          style="background-color: #c90000; !important"
                          class="bid-info-sub"
                          @click="rejectOffer(auctionEdit)"
                        >
                          Reject Negotiation
                        </button>
                      </div>
                    </div>
                    <div class="col-4">
                      <div>
                        <button
                          v-if="auctionEdit.approveStatus != true"
                          class="bid-info-sub"
                          style="background-color:#648ED1; !important"
                          @click="negotiateOffer(auctionEdit)"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div class="col-4" v-if="auctionEdit.approveStatus != true">
                      <div v-if="auctionEdit.sellerNegAmount">
                        <button
                          v-if="auctionEdit.approveStatus == true"
                          class="bid-info-sub"
                          style="background-color: rgb(32, 122, 32); !important"
                          @click="confirmOffer(auctionEdit)"
                        >
                          Confirm
                        </button>
                        <button
                          v-else
                          class="bid-info-sub"
                          style="background-color: #4CAF50; !important"
                          @click="acceptOffer(auctionEdit)"
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning pending-alert" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning pending-alert" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>

                <!-- cancel negotiation / reject negotiation  /accept negotiation-->
                <div
                  class="overlay-bid-btn warningStyle"
                  v-if="cancelNegotiationModal && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(cancelNegotiationModal = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning Alert</h4>
                  <p>
                    Are you sure want to add to
                    <strong>{{ " " + modeNeg }}</strong>
                    {{ "  " + modelnameAuc + " " + negotiationAmount + "  " }}
                    for
                    <span>{{
                      auctionEdit.brandName + "" + auctionEdit.modelName
                    }}</span>
                    ? ! this action is irreversible .
                  </p>

                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="cancelNegotiationModal = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="updateNegotiationState(auctionEdit, modeNeg)"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
                <!-- Aucction Status Alert Model -->
                <div
                  class="overlay-bid-btn warningStyle"
                  v-if="openShowModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openShowModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning</h4>
                  <p>
                    Are you sure you want to add
                    <span
                      >{{ auctionEdit.brandName }} {{ auctionEdit.modelName }}
                      {{ auctionEdit.manYear }}</span
                    >
                    to {{ modelnameAuc }}?
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openShowModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="changeAucStatus(auctionEdit, modelnameAuc)"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="auctionStatusMessage == 'Success'"
                  >
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="auctionStatusMessage">
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn warningStyle"
                  v-if="openNegotiationModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openNegotiationModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4 style="color: white">Negotiation Alert</h4>
                  <p>
                    Are you sure want to
                    {{ "  " + modelnameAuc + " " + negotiationAmount + "  " }}
                    for
                    <span>{{
                      auctionEdit.brandName + "" + auctionEdit.modelName
                    }}</span>
                    ?
                  </p>

                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openNegotiationModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="
                            openNegotiationModel = addNegotiation(
                              auctionEdit,
                              negotiationAmount
                            )
                          "
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenu from "@/components/sideMenu.vue";
import userService from "@/services/user.service";
import Config from "@/config";
import Alert from "../../components/Alert.vue";
import moment from "moment";
import navbar from "@/components/navbar.vue";
export default {
  components: {
    sideMenu,
    PageNation,
    NotFound,
    Alert,
    navbar,
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: [],
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
      openModel: false,
      openShowModel: false,
      auctionEdit: [],
      message: "",
      modelnameAuc: "",
      negotiationAmount: null,
      openNegotiationModel: false,
      customerNegAmount: null,
      auctionStatusMessage: "",
      modeNeg: "",
      negotiationDetails: "",
      negButton: "tile-bid-now",
      language: Config.BASE_LANG,
      negotiationTimeLine: [],
      cancelNegotiationModal: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.customerId != "undefined") {
        this.getpendingCustomerAuctions(this.userinfo.customerId);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.customerNegAmount = 200000;
  },
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime).format("MMMM Do YYYY, h:mm");
    },
    async updateNegotiationState(aucInfo, negStatus) {
      if (negStatus == "Rejected") {
        await this.updNegHistory(aucInfo, "Rejected", 0);
        // await  this.updAuctionStatus(aucInfo, "Rejected");
      } else if (negStatus == "Sale Confirmed") {
        await this.updNegHistory(aucInfo, "Sale Confirmed", 1);
        await this.updAuctionStatus(aucInfo, "Sale Confirmed");
      }
    },
    rejectOffer(e) {
      this.cancelNegotiationModal = !this.cancelNegotiationModal;
      this.modelnameAuc = ". Reject seller offer amount";
      this.auctionEdit = e;
      this.modeNeg = "Rejected";
      if (e.custNegAmount != null) {
        this.negotiationAmount = e.custNegAmount;
      } else {
        this.negotiationAmount = e.LatestBidAmount;
      }
      // this.changeAucStatus(e, this.modeNeg);
    },
    negotiateOffer(negotiationDetail) {
      let offerAmount = 0;
      this.message = "";

      if (
        parseInt(
          this.negotiationAmount == null || !this.negotiationAmount
            ? 0
            : this.negotiationAmount
        ) <= 0
      ) {
        this.message = "Plaese enter a valid negotiation amount";
      } else if (this.negotiationDetails.customerNegAmount != null) {
        if (
          parseInt(this.negotiationAmount) > negotiationDetail.custNegAmount
        ) {
          offerAmount = parseInt(this.negotiationAmount);
        } else {
          this.message =
            "Negotiation amount should be greater than  last offer amount";
        }
      } else if (
        parseInt(this.negotiationAmount) <= negotiationDetail.LatestBidAmount
      ) {
        this.message = "Negotiation amount should be greater than  bid amount";
      } else {
        offerAmount = parseInt(this.negotiationAmount);
      }
      if (offerAmount > 0) {
        this.message = "";
        this.modeNeg = "Negotiate";
        this.auctionEdit = negotiationDetail;
        this.openNegotiationModel = !this.openNegotiationModel;
        this.modelnameAuc = " change offer amount to: ";
      }
    },
    acceptOffer(e) {
      // alert("Accepted offer amount :" +  e.LatestBidAmount)
      //  var self = this;
      //  setTimeout(function () {
      //   self.closeModel();
      // }, 2000);
      this.modeNeg = "Sale Confirmed";
      this.cancelNegotiationModal = !this.cancelNegotiationModal;
      this.modelnameAuc = ",accept seller offer amount";
      this.auctionEdit = e;

      if (e.sellerNegAmount != null) {
        this.negotiationAmount = e.sellerNegAmount;
      } else if (e.custNegAmount != null) {
        this.negotiationAmount = e.custNegAmount;
      } else {
        this.negotiationAmount = e.LatestBidAmount;
      }
      // this.changeAucStatus(e, this.modeNeg);
    },
    updAuctionStatus(negotiationDetail, auctionState) {
      // console.log(negotiationDetail, r);
      userService
        .updAuctionStatus(negotiationDetail, auctionState, "Seller")
        .then((response) => {
          this.auctionStatusMessage = response.data.Message;
          if (this.auctionStatusMessage == "Success") {
            //  location.reload();
            this.auctionStatusMessage = "";
            this.$toast.success("Success ", {
              position: "top",
              duration: 3000,
              queue: true,
            });
            var self = this;
            setTimeout(function () {
              self.closeopenShowModel();
            }, 2000);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("!Oops sothing went wrong. try again", {
            position: "top",
            duration: 3000,
            queue: true,
          });
        });
    },
    changeAucStatus(negotiationDetail, auctionState) {
      userService
        .updAuctionStatus(negotiationDetail, auctionState)
        .then((response) => {
          this.auctionStatusMessage = response.data.Message;
          if (this.auctionStatusMessage == "Success") {
            //  location.reload();
            this.$toast.success("Success ", {
              position: "top",
              duration: 3000,
              queue: true,
            });
            var self = this;
            setTimeout(function () {
              self.closeopenShowModel();
            }, 1000);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("!Oops sothing went wrong. try again", {
            position: "top",
            duration: 3000,
            queue: true,
          });
        });
    },
    SubmitBid() {
      this.message = "Success";
      var self = this;
      setTimeout(function () {
        self.closeModel();
      }, 2000);
    },
    closeModel() {
      this.message = "";
      this.openModel = false;
    },
    closeopenShowModel() {
      this.auctionStatusMessage = "";
      this.openShowModel = false;
      location.reload();
    },
    reAuction(e) {
      this.auctionEdit = e;
      this.openModel = !this.openModel;
      this.openShowModel = false;
      this.getNegotiationTimeLine(e);
    },
    ShowAlert(e, r) {
      this.modelnameAuc = r;
      this.auctionEdit = e;
      this.openShowModel = !this.openShowModel;
      this.openNegotiationModel = false;
      this.openModel = false;
    },
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    updNegHistory(auctionDetails, aucStatus, winStatus) {
      //  console.log(auctionDetails, aucStatus, winStatus);
      userService
        .updNegHistory(auctionDetails, aucStatus, winStatus, "Customer")
        .then((response) => {
          this.auctionStatusMessage = response.data.Message;
          // if (this.auctionStatusMessage == "Success") {
          //   //  location.reload();
          //   // this.$toast.success("Success ", {
          //   //   position: "top",
          //   //   duration: 3000,
          //   // });
          //   // var self = this;
          //   // setTimeout(function () {
          //   //   self.closeopenShowModel();
          //   // }, 1000);
          // }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("!Oops sothing went wrong. try again", {
            position: "top",
            duration: 3000,
            queue: true,
          });
        });
    },

    addNegotiation(negotiationDetail, offerAmount) {
      if (this.modeNeg == "Sale Confirmed") {
        this.updNegHistory(negotiationDetail, this.modeNeg, 1);
        this.changeAucStatus(negotiationDetail, "Sale Confirmed");
      } else if (this.modeNeg == "Lost") {
        this.updNegHistory(negotiationDetail, this.modeNeg, 0);
        this.changeAucStatus(negotiationDetail, "Lost");
      } else if (this.modeNeg == "Negotiate") {
        userService
          .insNegotiationDetails(negotiationDetail, offerAmount, "Customer")
          .then((response) => {
            this.auctionStatusMessage = response.data.Message;
            if (this.auctionStatusMessage == "Success") {
              //  location.reload();
              this.$toast.success("Success ", {
                position: "top",
                duration: 3000,
                queue: true,
              });
              var self = this;
              setTimeout(function () {
                self.closeopenShowModel();
              }, 2000);
            }
          })
          .catch((e) => {
            console.log(e);
            this.$toast.error("!Oops sothing went wrong. try again", {
              position: "top",
              duration: 3000,
              queue: true,
            });
          });
      }
    },

    getpendingCustomerAuctions(custId) {
      let id = custId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getCustomerAuctionsStatus(id, "Pending")
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
            
            //console.log(this.AuctionsDetails);
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );

            for (let index = 0; index < this.AuctionsDetails.length; index++) {
              const element = this.AuctionsDetails[index];
              this.negotiationDetails = element;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getNegotiationTimeLine(e) {
      let id = e.aucId;

      if (id == "undefind") {
        console.log("undefind");
      } else {
        if (e.CustomerRank < 2) {
          e.isFirstCustomer = 1;
        } else {
          e.isFirstCustomer = 0;
        }
        e.ProcessCustomerId = e.custId;

        userService
          .getNegotiationTimeLine("customer", e)
          .then((response) => {
            this.negotiationTimeLine = response.data.Data;
            //console.log(this.negotiationTimeLine);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.neg-a {
  padding: 0px 0px;
  height: 30px !important;
}
.negTable tr {
  margin-top: 6px;
  display: inline-block;
}

.overlay-bid-btn {
  position: fixed;
  z-index: 9999;
  top: 0%;
  left: 10%;
  padding: 20px 30px;
  background-color: rgb(255 255 255);
  position: fixed;
  /* width: 50%; */
  margin: 100px 30%;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #00000069;
}
.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.pending-cust-pop {
  width: 50%;
}

.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.custom-btn {
  /* width: 130px; */
  height: 40px;
  /* color: #fff; */
  border-radius: 5px;
  padding: 10px 25px;
  /* font-family: 'Lato', sans-serif; */
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  /* box-shadow: inset 2px 2px 2px 0px rgb(255 255 255 / 50%), 7px 7px 20px 0px rgb(0 0 0 / 10%), 4px 4px 5px 0px rgb(0 0 0 / 10%); */
  outline: none;
}

/* 11 */
.btn-11 {
  border: none;
  width: 97%;
  /* background: #d0e45f;
    background: linear-gradient(0deg, #648ED1 0%, #648ED1 100%); */
  background: rgb(251, 33, 117);
  background: linear-gradient(
    0deg,
    rgba(251, 33, 117, 1) 0%,
    rgba(234, 76, 137, 1) 100%
  );

  color: #fff;
  overflow: hidden;
}
.btn-11:hover {
  text-decoration: none;
  color: #fff;
}
.btn-11:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}
.btn-11:hover {
  opacity: 0.7;
}
.btn-11:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
.negTable tr td {
  width: 200px;
}
.not-found-imgg {
  width: 90% !important;
}
</style>
